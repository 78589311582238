<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Register</h5>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                </p>
            </div>
        </div>
    </div>

    <div class="row">
        <Message severity="error" :closable="false">
            This is a BETA site, be aware this is not a registration for Certass
        </Message>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Register</h5>

                <Message v-if="showError" severity="error" :closable="false">
                    {{
                        errorMessage ||
                            'Issues were found with the supplied data'
                    }}</Message
                >

                <form @submit.prevent="submit">
                    <div class="p-field">
                        <h5>Email address <field-required-notification /></h5>
                        <InputText
                            type="email"
                            class="form-input"
                            :class="{
                                'p-invalid': validated('username')
                            }"
                            id="email"
                            name="email"
                            autocomplete="username"
                            v-model.trim="form.username"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="username"
                        />
                    </div>

                    <div class="p-field">
                        <h5>Password <field-required-notification /></h5>
                        <Password
                            v-model="form.password"
                            id="password"
                            name="password"
                            autocomplete="new-password"
                            :class="{
                                'p-invalid': validated('password')
                            }"
                        >
                            <template #header>
                                <h6>Password</h6>
                            </template>
                            <template #footer>
                                <Divider />
                                <p class="p-mt-2">Must contain</p>
                                <ul
                                    class="p-pl-2 p-ml-2 p-mt-0"
                                    style="line-height: 1.5"
                                >
                                    <li>At least one lowercase</li>
                                    <li>At least one uppercase</li>
                                    <li>At least one numeric</li>
                                    <li>Minimum 8 characters</li>
                                </ul>
                            </template>
                        </Password>
                        <ValidationText
                            v-bind:errors="errors"
                            field="password"
                        />
                    </div>

                    <div class="p-field">
                        <h5>
                            Password Confirmation
                            <field-required-notification />
                        </h5>

                        <InputText
                            type="password"
                            :class="{
                                'p-invalid': validated('passwordConfirmation')
                            }"
                            id="passwordConfirm"
                            name="passwordConfirm"
                            autocomplete="new-password"
                            v-model="form.passwordConfirm"
                        />
                        <ValidationText
                            v-bind:errors="errors"
                            field="passwordConfirm"
                        />
                    </div>

                    <div class="p-field">
                        <Button
                            type="submit"
                            click="submit"
                            class="btn btn-primary btn-lg form-input"
                            :disabled="!fieldsCompleted || loading"
                        >
                            <span v-if="loading"
                                ><i class="pi pi-spin pi-spinner"></i
                            ></span>
                            <span v-else>Submit</span>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import Password from 'primevue/password'
import Divider from 'primevue/divider'
import Message from 'primevue/message'
import { mapActions } from "vuex"
import ValidationText from '@/components/helpers/ValidationText'

export default {
    name: 'Register',
    components: {Password, Divider, Message, ValidationText},
    data: () => {
        return {
            form: {
                username: '',
                password: '',
                passwordConfirm: ''
            },
            showError: false,
            errorMessage: '',
            errors: [],
            loading: false
        }
    },
    computed: {
        fieldsCompleted() {
            if (this.form.username && this.form.username.includes('@') && this.form.username.includes('.') && this.form.password && this.form.passwordConfirm && this.form.password == this.form.passwordConfirm) {
                return true
            }
            return false
        }
    },
    methods: {
        validated(field) {
            const error = this.errors.find((value) => {
                return value.field == field
            })

            return error ? true : false
        },
        ...mapActions(["register"]),
        async submit() {
            this.loading = true
            const User = new FormData()
            User.append("username", this.form.username)
            User.append("password", this.form.password)
            User.append("passwordConfirm", this.form.passwordConfirm)

            try {
                this.showError = false
                this.errors = []
                await this.register(User)
                this.$router.push("/applicant")
            } catch (error) {
                if (error.response.status == 409) {
                    this.errors = [{field: 'username', message: 'This email is already in use, please login or use another email.'}]
                }
                else if (error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
                this.showError = true
                this.loading = false
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-input {
    width: 100%;
}
.p-field {
    display: block;
    margin-top: 20px;
}
</style>
