<template>
    <small :id="defineId" class="validation-text">{{
        displayErrorMessage
    }}</small>
</template>

<script lang="js">

export default {
    name: 'ValidationText',
    props: {
        field: {type: String, required: true},
        errors: {type: Array, required: true}
    },
    computed: {
        displayErrorMessage() {
            const error = this.errors.find((value) => {
                return value.field == this.field
            })
            return error ? error.message : ''
        },
        defineId() {
            return 'validator-' + this.field
        }
    },
}
</script>
<style scoped lang="scss">
.validation-text {
    display: block;
    color: rgb(138, 9, 9);
}
</style>
